import PropTypes from "prop-types";
import _ from "lodash";
import { Theme } from "@mui/material";
import * as Sentry from "@sentry/react";
import { emailRegexPattern } from "../constants/regexPatterns";
import {
  DARK_MODE,
  DEVICE_TRACKING_INFO,
  SIGN_UP_SESSION_DATA_KEY,
} from "../../authentication/constants/authenticationConstants";

export function hasSpaces(str) {
  return str.indexOf(" ") !== -1;
}

export function capitalize(str, normalize = false) {
  if (typeof str === "string" && !isEmptyOrSpaces(str)) {
    if (normalize) {
      str = str.replace("_", " ");
    }
    if (hasSpaces(str)) {
      let results = [];
      let array = str.split(" ");
      array.forEach((word) => {
        if (normalize) {
          results.push(word[0].toUpperCase() + word.slice(1).toLowerCase());
        } else {
          results.push(word.replace(/^\w/, (c) => c.toUpperCase()));
        }
      });
      return results.join(" ");
    }
    if (normalize) {
      return str[0].toUpperCase() + str.slice(1).toLowerCase();
    } else {
      return str.replace(/^\w/, (c) => c.toUpperCase());
    }
  } else {
    return "";
  }
}

export function planLevelToDisplayTitle({
  planLevel,
  displayTitle,
}: {
  planLevel?: string,
  displayTitle?: string,
}) {
  if (planLevel) {
    switch (planLevel) {
      case "view":
        return "Free";
      case "monitor":
        return "Standard";
      case "insights":
        return "Plus";
      case "protect":
        return "Premium";
      case "family":
        return "Premium + Child ID Monitoring";
      default:
        return "Free";
    }
  } else if (displayTitle) {
    switch (displayTitle) {
      case "Free":
        return "view";
      case "Standard":
        return "monitor";
      case "Plus":
        return "insights";
      case "Premium":
        return "protect";
      case "Premium + Child ID Monitoring":
        return "family";
      default:
        return "view";
    }
  }
}

export function getIndefiniteArticle(str) {
  if (typeof str === "string" && !isEmptyOrSpaces(str)) {
    return ["a", "e", "i", "o", "u"].includes(str[0].toLowerCase())
      ? "an"
      : "a";
  }
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => {
    if (isArray(object[key])) {
      return object[key].find((entry) => {
        if (entry?.charAt(entry.length - 1) === "*") {
          return value.includes(entry.slice(0, entry.length - 1));
        } else {
          return entry === value;
        }
      });
    }
    return object[key] === value;
  });
}

export function read_prop(obj, prop) {
  if (isEmptyOrSpaces(obj)) {
    return undefined;
  }
  if (!Object.keys(obj).includes(prop)) {
    return undefined;
  }
  return obj[prop];
}

export function getDateAgo(date, days, dateObj = false) {
  let dateCopy = new Date(date);

  dateCopy.setDate(date.getDate() - days);
  if (dateObj) {
    return dateCopy;
  } else {
    return dateCopy.getDate();
  }
}

export function scoreToGrade(value) {
  if (value >= 90) {
    return "a";
  }
  if (value >= 80) {
    return "b";
  }
  if (value >= 70) {
    return "c";
  }
  if (value >= 60) {
    return "d";
  }
  if (value === 0 || value === -1) {
    return "na";
  }
  return "f";
}

export function infoExToClass(value) {
  let result;
  if (value < 3) {
    result = "d";
  } else if (value < 5) {
    result = "c";
  } else if (value < 7) {
    result = "b";
  } else if (value <= 10) {
    result = "a";
  } else if (value === 0) {
    result = "na";
  } else {
    result = "f";
  }
  return result;
}

export function ordinalSuffixOf(i) {
  if (!_.isFinite(i) && i !== "") {
    return "";
  }
  let j = i % 10,
    k = i % 100;
  if (i === 0 || i === undefined) {
    return "";
  }
  if (j === 1 && k !== 11) {
    return "st";
  }
  if (j === 2 && k !== 12) {
    return "nd";
  }
  if (j === 3 && k !== 13) {
    return "rd";
  }
  return "th";
}

export function isEmptyOrSpaces(value) {
  if (Number.isFinite(value)) {
    return false;
  }
  if (typeof value === "boolean") {
    return value;
  }
  if (isArray(value)) {
    return value.length < 1;
  } else if (isObject(value) && typeof value !== "string") {
    return Object.keys(value).length === 0;
  } else if (value === undefined) return true;
  return value === null || value.match(/^ *$/) !== null;
}

export function isArray(obj) {
  return toString.call(obj) === "[object Array]";
}

export function isObject(obj) {
  const type = typeof obj;
  return type === "function" || (type === "object" && !!obj && obj !== null);
}

export function flattenObject(object, tempData, idx = null) {
  if (object === null || object === undefined) {
    return null;
  }
  Object.entries(object).forEach(([key, value]) => {
    if (!isEmptyOrSpaces(value) && !isObject(value) && !isArray(value)) {
      key = key.replace("_", " ");
      tempData.push({ label: idx ? `${key}${idx}` : key, value: value });
    } else if (isArray(value)) {
      value.forEach((newObject, i) => {
        flattenObject(newObject, tempData, i);
      });
    } else if (isObject(value)) {
      flattenObject(value, tempData, idx);
    }
  });
}

export function removeItemFromArrayByIndex(list, idx) {
  const halfBeforeTheUnwantedElement = list.slice(0, idx);
  const halfAfterTheUnwantedElement = list.slice(idx + 1);
  return halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement);
}

export function pascalPad(str) {
  return (
    str
      // Look for long acronyms and filter out the last letter
      .replace(/([A-Z]+)([A-Z][a-z])/g, " $1 $2")
      // Look for lower-case letters followed by upper-case letters
      .replace(/([a-z\d])([A-Z])/g, "$1 $2")
      // Look for lower-case letters followed by numbers
      .replace(/([a-zA-Z])(\d)/g, "$1 $2")
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
      // Remove any white space left around the word
      .trim()
  );
}

export function replaceHyphens(str) {
  return str.replace("-", " ").replace("@", "").trim();
}

export const gradeStyles = {
  a: { color: "#128d1a", fontFamily: "Work Sans", fontWeight: 600 },
  b: { color: "#274893", fontFamily: "Work Sans", fontWeight: 600 },
  c: { color: "#f9520b", fontFamily: "Work Sans", fontWeight: 600 },
  d: { color: "#c4090e", fontFamily: "Work Sans", fontWeight: 600 },
  f: { color: "#c4090e", fontFamily: "Work Sans", fontWeight: 600 },
  na: { color: "#757575", fontFamily: "Work Sans", fontWeight: 600 },
};

export const getColorForGrade = (grade: string, theme: Theme) => {
  return theme.palette.gradeStyles?.[grade] ?? theme.palette.gradeStyles.na;
};

export const getColorForInfoExScore = (score: number, theme: Theme) => {
  return score < 3
    ? theme.palette.gradeStyles.d
    : score < 5
    ? theme.palette.gradeStyles.c
    : score < 7
    ? theme.palette.gradeStyles.b
    : score <= 10
    ? theme.palette.gradeStyles.a
    : theme.palette.gradeStyles.na;
};

export const validateEmail = (email) => {
  return String(email).toLowerCase().match(emailRegexPattern);
};

export function dedupListOfObjects(inArray) {
  return inArray.filter(
    (v, i, a) =>
      a.findIndex((v2) => JSON.stringify(v2) === JSON.stringify(v)) === i
  );
}

//TODO: Make Unit Tests
export function objsHaveSameData(obj1, obj2) {
  const obj1Length = Object.keys(obj1).length;
  const obj2Length = Object.keys(obj2).length;

  if (obj1Length === obj2Length) {
    return Object.keys(obj1).every(
      (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
    );
  }
  return false;
}

//TODO: Make Unit Tests
export function handleCopyToClipboard(text, callback = () => {}) {
  navigator.clipboard.writeText(text);
  callback(true);
}

export function hexToRgb(hex, alpha = null) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgb${alpha ? "a" : ""}(${parseInt(result[1], 16)}, ${parseInt(
        result[2],
        16
      )}, ${parseInt(result[3], 16)}${alpha ? ", " + alpha : ""})`
    : null;
}

export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export function fromBufferToB64(buffer: ArrayBuffer): string {
  if (buffer == null) {
    return null;
  }
  let binary = "";
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return global.btoa(binary);
}

export function fromB64ToArray(str: string): Uint8Array {
  if (str == null) {
    return null;
  }
  try {
    const binaryString = global.atob(str);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  } catch (exc) {
    Sentry.captureException(exc);
    return false;
  }
}

export function fromBufferToHex(buffer: ArrayBuffer): string {
  const bytes = new Uint8Array(buffer);
  return Array.prototype.map
    .call(bytes, (x: number) => ("00" + x.toString(16)).slice(-2))
    .join("");
}

export function fromBufferToUtf8(buffer: ArrayBuffer): string {
  const bytes = new Uint8Array(buffer);
  const encodedString = String.fromCharCode.apply(null, bytes);
  try {
    return decodeURIComponent(escape(encodedString));
  } catch {
    return escape(encodedString);
  }
}

export function formatFileSize(bytes, decimalPoint) {
  if (bytes == 0) return "0 Bytes";
  var k = 1000,
    dm = decimalPoint || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export function transformPhoneNumberToCognitoFormat(
  originalPhoneNumber
): string {
  let newPhoneNumber = originalPhoneNumber.replaceAll(/[()\- ]+/gi, "");
  if (!newPhoneNumber.match(/^\+[0-9]{10,16}$/)) {
    newPhoneNumber = "+1" + newPhoneNumber;
  }
  return newPhoneNumber;
}

transformPhoneNumberToCognitoFormat.propTypes = {
  originalPhoneNumber: PropTypes.string.isRequired,
};

export function getRandomString(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function parseJwt(token) {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function roundToTwoDecimals(number) {
  return Math.round((number + Number.EPSILON) * 100) / 100;
}

export const median = (array) => {
  array.sort((a, b) => b - a);
  const length = array.length;
  if (length % 2 === 0) {
    return (array[length / 2] + array[length / 2 - 1]) / 2;
  } else {
    return array[Math.floor(length / 2)];
  }
};

export const sortObjectAlphabetically = ({
  list,
  field,
  descending = false,
}) => {
  return _.orderBy(
    [...list],
    [
      (o) => {
        return o?.[field]?.toLowerCase() ?? "";
      },
    ],
    [!descending ? "asc" : "desc"]
  );
};
export const excludedPaths = [
  "/health",
  "/login",
  "/authentication/oauth2/token",
  "/sign-up",
  "/sign-up/start",
  "/sign-up/confirm-account",
  "/sign-up/additional-info",
  "/sign-up/select-plan",
  "/sign-up/persona-monitoring-enroll",
  "/sign-up/welcome",
  "/expired-session",
  "/500",
  "/404",
];

export function downloadUrl(url, filename = "download.txt") {
  var link = document.createElement("a");
  link.download = filename;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    link.remove();
  }, 0);
}

export function resetStorage(
  storageType: "local" | "session",
  deleteAccount: boolean = false
) {
  const storageInterface: Storage | null =
    storageType === "local"
      ? localStorage
      : storageType === "session"
      ? sessionStorage
      : null;
  if (!storageInterface) {
    console.error("resetStorage was called with a non valid storageType", {
      storageType,
    });
    return false;
  }
  // TODO: Add typing once you have converted to typescript
  // const itemsToRetainThroughClearing: KeyValuePair[] = [];
  const itemsToRetainThroughClearing = [];
  for (let i = 0; i < storageInterface.length; i++) {
    const key = storageInterface.key(i);
    if (storageType === "local") {
      switch (key) {
        case DARK_MODE:
        case DEVICE_TRACKING_INFO:
          if (deleteAccount && key === DARK_MODE) {
            break;
          }
          itemsToRetainThroughClearing.push({
            key,
            value: storageInterface.getItem(key),
          });
          break;
      }
    }
    if (storageType === "session") {
      switch (key) {
        case SIGN_UP_SESSION_DATA_KEY:
          itemsToRetainThroughClearing.push({
            key,
            value: sessionStorage.getItem(key),
          });
          break;
      }
    }
  }
  storageInterface.clear();
  itemsToRetainThroughClearing.forEach((item) => {
    storageInterface.setItem(item.key, item.value);
  });
  return true;
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function defaultTo(value: any, defaultValue?: any) {
  return !_.isEmpty(value) ? value : defaultValue;
}
